import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { User } from '../AuthHelper/user';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  url = environment.api_url;
  // constructor(private http: HttpClient) { }

  // public Authenticate(inputobj) {
  //   return this.http.post(this.url + "user/login", inputobj);
  // }
  public Signup(inputobj) {
    return this.http.post(this.url + "user/signup", inputobj).pipe(catchError(this.handleError));;
  }
  handleError(error: HttpErrorResponse) {
    return throwError(error.error);
  }


  private userSubject: BehaviorSubject<User | null>;
  public user: Observable<User | null>;

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
    this.userSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('user')!));
    this.user = this.userSubject.asObservable();
  }

  public get userValue() {
    return this.userSubject.value;
  }

  Login(inputobj) {
    return this.http.post(this.url + "user/login", inputobj).pipe(map(user => {
      localStorage.setItem('user', JSON.stringify(user));
      this.userSubject.next(user);
      return user;
    }));
  }

  Logout() {
    localStorage.removeItem('user');
    this.userSubject.next(null);
    this.router.navigate(['/auth/login']);
  }

}
