<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()"><span>Billing</span></a>
  </div>
  <nb-select hidden [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select>
</div>

<div class="header-container">
  <nb-actions size="small">

    <!-- <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>
    <nb-action class="control-item" icon="email-outline"></nb-action> 
    <nb-action class="control-item" icon="bell-outline"></nb-action>-->
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" (click)="OnClickUserProfile()">
      <nb-user [nbContextMenu]="userMenu" [onlyPicture]="userPictureOnly" [name]="user?.username"
        [picture]="user?.picture">
      </nb-user>
    </nb-action>
    <nb-action class="control-item" icon="power-outline" (click)="Logout()"></nb-action>
  </nb-actions>
</div>